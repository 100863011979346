import React from "react";
import { Card, CardBody, Badge } from "reactstrap";

import { Fade } from "react-reveal";

const FeedbackCard = ({ data }) => {
	return (
		<Fade right duration={1000} distance="40px">
			<Card className="card-lift--hover shadow mt-4" style={{"min-height": "180px"}}>
				<CardBody>
					<div className="d-flex px-3">
						<div className="pl-4">
							<h6 className="text-info">{data.name}</h6>
							<div className="description mt-3">
								<a href={data.href} target="_blank">
									<img style={{"width":"80%"}} src={data.src}/>
								</a>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		</Fade>
	);
};

export default FeedbackCard;

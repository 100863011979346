import React from 'react';

import {
    Card,
    Col,
    Row,
    Container,
} from "reactstrap";

import SocialLinks from "../components/SocialLinks";

const GithubProfileCard = ({prof}) => {
    return ( 
            <Card className="section bg-gradient-info shadow-lg border-0">
                <Container className="">
                <div className="p-2">
                  <Row className="">
                    {/*<Col className="order-lg-2" lg="4">*/}
                    {/*  <img src={prof.avatar_url} style={{width: "200px"}} alt="" className="rounded-circle img-center img-fluid shadow shadow-lg--hover mb-4"/>*/}
                    {/*</Col>*/}
                    <Col lg="8" className="order-lg-1">
                      <h2 className="text-white">
                        Megalith Solutions
                      </h2>
                      <p className="lead text-white mt-3">
                        CONTACT US
                      </p>
                        <a href = {"mailto: " + prof.contact_email} className="mr-2">
                      <div className="my-3 icon-shape bg-gradient-white shadow rounded text-info">
                            <i className="ni ni-email-83 text-info mr-2" />
                            Email Us
                        </div>
                        </a>
                        <a href = {"tel: " + prof.contact_phone} className="ml-2">
                            <div className="my-3 icon-shape bg-gradient-white shadow rounded text-info">
                                <i className="ni ni-mobile-button text-info mr-2" />
                                Call Us
                            </div>
                        </a>
                        <SocialLinks />
                    </Col>
                      <Col className="order-lg-2" lg="4">
                          <h4 className="text-white">
                              QUICK LINKS
                          </h4>
                          <a href={"https://therealestateiq.com"} target={"_blank"}>
                              <p className="text-white">
                                  Portal
                              </p>
                          </a>
                          <a href={"https://loyalty.megalithinc.com"} target={"_blank"}>
                              <p className="text-white">
                                  Loyalty
                              </p>
                          </a>
                          <a href={"https://cma.therealestateiq.com"} target={"_blank"}>
                              <p className="text-white">
                                  CMA
                              </p>
                          </a>
                          <a href={"https://megalithinc.com"} target={"_blank"}>
                              <p className="text-white">
                                  Landing Page
                              </p>
                          </a>
                      </Col>
                  </Row>
                </div>
                <p className="mt-4 text-white" style={{"text-align": "right", "font-size": "0.7rem"}}>
                    Powered by <strong><a className="text-white" href={"https://megalithinc.com"} target={"_blank"}>Megalith Solutions</a></strong>
                </p>
                </Container>
              </Card>
     );
}
 
export default GithubProfileCard;